import store from '@/store'
import { createAuthGuard } from '@auth0/auth0-vue'
import { App } from 'vue'
import { createRouter as createVueRouter, createWebHistory, Router } from 'vue-router'

export function createRouter(app: App): Router {
  const router = createVueRouter({
    routes: [
      {
        path: '/',
        beforeEnter: createAuthGuard(app),
        component: () => import('@/views/_layout.vue'),
        children: [
          { path: '', component: () => import('@/views/Index.vue'), meta: { title: '記録履歴' } },
          { path: 'add', component: () => import('@/views/Add.vue'), meta: { title: '健康データの記録' } },
          { path: 'notification', component: () => import('@/views/Notification.vue'), meta: { title: 'お知らせ' } },
          { path: 'approval', component: () => import('@/views/Approval.vue'), meta: { title: 'アクセス管理' } },
        ],
      },
    ],
    history: createWebHistory(process.env.BASE_URL),
  })

  router.beforeEach(async to => {
    // フロントAPPバージョンのチェック（アップデートがあれば強制リロード）
    if (process.env.NODE_ENV === 'production') await store.dispatch('version', to.path)
  })

  return router
}
